.modal{
    position:absolute;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
    background-color:rgba(0,0,0,0.6);
    z-index: 99999;
}
.modal .container{
    position:fixed;
    max-width: 600px;
    top:20%;
    left:25%;
    right:0;
    margin:0 auto;
    padding:15px;
    background-color: transparent;
}
@media screen and (max-width:920px){
    .modal .container{
      position:fixed;
      max-width: 600px;
      top:20%;
      left:0;
      right:0;
      margin:0 auto;
      padding:15px;
      background-color: transparent;
  }
}
.modal .close{
    background-color:darkred;
    border:0;
    color:#fff;
    position:absolute;
    top:15px;
    right:15px;
    justify-content: center;
    align-items: center;
    padding:4px 15px;
    border-radius: 5px;
}
.modal h2{
    margin-bottom: 1.2em;
    font-size:2em;
}
.modal span{
    font-weight: bold;
    font-size: 1.2em;
    color:#121212;
}
.modal span i{
    font-weight: 400;
    margin-right: 1em;
    padding:2px 8px;
    border-radius:3px;
    font-style: normal;
}

.modal video {
    width: 100%;
  }
  
  .modal .video-wrapper {
    width: 100%;
    max-width: 700px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rbga(0,0,0,.8);
  }
  
  .modal .video-wrapper:hover .controls {
    transform: translateY(0%);
  }
  
  .modal .controls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: 30px;
    padding: 14px;
    width: 100%;
    max-width: 500px;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    transform: translateY(150%);
    transition: all 0.3s ease-in-out;
  }
  
  .modal .actions button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .modal .actions button i {
    background-color: none;
    color: white;
    font-size: 30px;
  }
  
  .modal input[type="range"] {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    height: 4px;
    width: 350px;
  }
  
  .modal input[type="range"]::-webkit-slider-thumb {
    cursor: pointer;
    height: 6px;
  }
  
  .modal input[type="range"]::-moz-range-progress {
    background: white;
  }
  
  .modal .velocity {
    appearance: none;
    background: none;
    color: white;
    outline: none;
    border: none;
    text-align: center;
    font-size: 16px;
  }
  
  .modal .mute-btn {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .modal .mute-btn i {
    background-color: none;
    color: white;
    font-size: 20px;
  }

